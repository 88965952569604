/**
 *  Vuex
 **/

import Vue from 'vue'
import Vuex from 'vuex'
import order from './modules/orderUtils' //订单工具使用

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    order,
  }
})
