<template>
  <div>
    <el-image :preview-src-list="srcList" ref="image"> </el-image>
  </div>
</template>

<script>
export default {
  name: 'DialogImg',
  data () {
    return {
      srcList: []
      
    }
  },
  methods: {
    previewImage (img) {
      this.srcList = [img]
      this.$nextTick(() => {
        this.$refs.image.clickHandler()
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-image__error {
  display: none;
}
</style>
