import axios from 'axios'
import router from '../router/index'
// 通过其值来区分运行环境
// const prefixurl =
//   process.env.NODE_ENV === 'development'
//     ? 'http://farmerstest.hengshunits.com/'
//     : 'https://farmers.hengshunits.com/'

// const prefixurl = 'http://farmersba.hengshunits.com/'
const prefixurl = 'https://farmers.hengshunits.com/'
// 创建axios实例
var instance = axios.create({
  baseURL: prefixurl
})

// 请求拦截器
instance.interceptors.request.use(config => {
  config.headers = {
    'X-Requested-With': 'XMLHttpRequest'
  }
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  return config
})

// 响应拦截器
instance.interceptors.response.use(
  // 请求成
  res => {
    if (res.data.code >= 200 && res.data.code < 300) {
      if (res.headers.authorization) {
        localStorage.setItem(
          'token',
          res.headers.authorization.split('Bearer')[1]
        )
      }
      return Promise.resolve(res.data)
    } else if (res.data.code === 401) {
      localStorage.removeItem('token')
      router.replace('/login')
    } else {
      // console.log(res.data)
      return Promise.reject(res.data)
    }
  },
  error => {
    const message = error.message || '网络错误'
    return Promise.reject(message)
  }
)

export default instance
