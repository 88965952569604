import { getinfo } from '../../api/login'
let userInfo = window.localStorage.getItem('userInfo')
export default {
  namespaced: true,
  state: {
    userInfo: JSON.parse(userInfo) || {}
  },
  mutations: {
    setUserInfo (state, value) {
      state.userInfo = value
    }
  },
  actions: {
    getUserInfo (store) {
      return getinfo().then(res => {
        // console.log(res)
        window.localStorage.setItem('userInfo', JSON.stringify(res.data))
        store.commit('setUserInfo', res.data)
      })
    }
  }
}
