import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import store from './vuex'
import '@/utils/element.js'
import '@/style/base.css'
import DialogImg from './components/dialog'

Vue.component('DialogImg', DialogImg)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
