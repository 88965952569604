<template>
  <div id="id">
    <router-view />
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#id {
  height: 100%;
}
</style>
<style lang="less">
.uploadDemo {
  display: inline-block;
  margin-left: 20px;
  border: none;
}
.uploadDemo /deep/.el-upload {
  border: none;
}
</style>
