import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    alias: '/'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home.vue'),
    children: [
      {
        path: '/home/market_list',
        name: 'market_list',
        component: () => import('@/views/home/layout/market_list.vue')
      },
      {
        path: '/home/productList',
        name: 'productList',
        component: () => import('@/views/home/productList/productList.vue')
      },
      {
        path: '/home/agencyList',
        name: 'agencyList',
        component: () => import('@/views/home/productList/agencyList.vue')
      },
      {
        path: '/home/commodityList',
        name: 'commodityList',
        component: () => import('@/views/home/productList/commodityList.vue')
      },
      {
        path: '/home/commodityLists',
        name: 'commodityLists',
        component: () => import('@/views/home/productList/commodityLists.vue')
      },
      {
        path: '/home/purchaseList',
        name: 'purchaseList',
        component: () => import('@/views/home/purchaseList/purchaseList.vue')
      },
      {
        path: '/home/supplier',
        name: 'supplier',
        component: () => import('@/views/home/purchaseList/supplier.vue')
      },
      {
        path: '/home/orderFormList',
        name: 'orderFormList',
        component: () => import('@/views/home/orderFormList/orderFormList.vue')
      },
      {
        path: '/home/backstageList',
        name: 'backstageList',
        component: () => import('@/views/home/backstageList/backstageList.vue')
      },
      {
        path: '/home/testingList',
        name: 'testingList',
        component: () => import('@/views/home/testingList/testingList.vue')
      },
      {
        path: '/home/evaluateList2',
        name: 'evaluateList2',
        component: () => import('@/views/home/evaluateList/evaluateList2.vue')
      },
      {
        path: '/home/electronicList',
        name: 'electronicList',
        component: () =>
          import('@/views/home/electronicList/electronicList.vue')
      },
      {
        path: '/home/WiseScreenList',
        name: 'WiseScreenList',
        component: () =>
          import('@/views/home/WiseScreenList/WiseScreenList.vue')
      },
      {
        path: '/home/particularsList',
        name: 'particularsList',
        component: () =>
          import('@/views/home/particularsList/particularsList.vue')
      },
      {
        path: '/home/MarketDivisionManagement',
        name: 'MarketDivisionManagement',
        component: () =>
          import('@/views/home/layout/MarketDivisionManagement.vue')
      },
      {
        path: '/home/MarketDivisionManagementPeople',
        name: 'MarketDivisionManagementPeople',
        component: () =>
          import('@/views/home/layout/MarketDivisionManagementPeople.vue')
      },
      {
        path: '/home/addManage',
        name: 'addManage',
        component: () => import('@/views/home/manage/addManage.vue')
      },
      {
        path: '/home/addManages',
        name: 'addManages',
        component: () => import('@/views/home/manage/addManages.vue')
      },
      {
        path: '/home/evaluateList',
        name: 'evaluateList',
        component: () => import('@/views/home/evaluateList/evaluateList.vue')
      },
      {
        path: '/home/electronicLists',
        name: 'electronicLists',
        component: () => import('@/views/home/electronicList/electronicLists')
      },
      {
        path: '/home/deviceMaintain',
        name: 'deviceMaintain',
        component: () => import('@/views/home/electronicList/deviceMaintain')
      },
      {
        path: '/home/managetabal',
        name: 'managetabal',
        component: () => import('@/views/home/manage/managetabal')
      },
      {
        path: '/home/stallList',
        name: 'stallList',
        component: () => import('@/views/home/stall/stallList')
      },
      {
        path: '/InspectUnit/list',
        name: 'InspectUnit',
        component: () => import('@/views/home/manage/InspectUnit/list')
      },
      {
        path: '/Patrolproject/list',
        name: 'Patrolproject',
        component: () => import('@/views/home/manage/Patrolproject/list')
      },
      {
        path: '/personnel/list',
        name: 'personnel',
        component: () => import('@/views/home/manage/personnel/list')
      },
      {
        path: '/InspectionRecord/list',
        name: 'InspectionRecord',
        component: () => import('@/views/home/manage/InspectionRecord/list')
      },
      {
        path: '/marketInformation',
        name: 'marketInformation',
        component: () => import('@/views/home/backstageList/MarketInformation')
      },
      {
        path: '/ranking',
        name: 'ranking',
        component: () => import('@/views/home/ranking/ranking')
      }
    ]
  },
  {
    path: '*',
    name: 'nofind',
    component: () => import('@/views/nofind/nofind.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
