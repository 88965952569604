import request from './request'

export const captchas = () => {
  return request({
    url: 'api/v1/captchas',
    method: 'POST'
  })
}
export const authorizations = data => {
  return request({
    url: 'admin/v1/authorizations',
    method: 'POST',
    data
  })
}
export const getinfo = () => {
  return request({
    url: 'admin/v1/info',
    method: 'GET'

  })
}
